<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">
        <!-- Searchbar Input -->
        <ion-toolbar v-if="showSearchBar">
          <ion-searchbar style="padding-top: 12px" mode="ios" ref="searchBarRef" v-model="searchKeyword" :placeholder="t('search')"
                        :cancel-button-text="t('cancel')" show-cancel-button="always" @ionCancel="showSearchBar = false"></ion-searchbar>
        </ion-toolbar>

        <!-- Page title & action buttons -->
        <ion-toolbar v-else>
          <ion-buttons slot="start">
            <ion-back-button class="back-icon" default-href="/home"></ion-back-button>
          </ion-buttons>

          <ion-title>
            {{ t('infos') }}
          </ion-title>

          <ion-buttons slot="primary">
            <ion-button router-link="/browsed-infos">
              <ion-icon slot="icon-only" :icon="timeOutline"></ion-icon>
            </ion-button>
            <ion-button router-link="/liked-infos">
              <ion-icon slot="icon-only" :icon="bookmarksOutline"></ion-icon>
            </ion-button>
            <ion-button @click="onClickSearchIcon()">
              <ion-icon slot="icon-only" :icon="search"></ion-icon>
            </ion-button>
          </ion-buttons>

        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>
      <ion-grid fixed>

        <div class="info-top-anchor" style="position: absolute; top: -100px; left: 0"></div>
        
        <info-card-list :infos="filterInfos()" segmentMode="md" anchorClass="info-top-anchor"></info-card-list>

      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, ref, watch } from 'vue';

// icons
import { search, arrowBack, bookmarksOutline, timeOutline,
         heart, heartOutline, shareSocialOutline, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonGrid,
        IonIcon, IonButtons, IonButton, IonBackButton,
        onIonViewDidEnter, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import InfoCardList from "@/components/info/InfoCardList.vue";

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { utils } from '@/composables/utils';

export default {
  name: 'InfoOverviewPage',
  components: { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonGrid,
                IonIcon, IonButtons, IonButton, IonBackButton,
                InfoCardList, LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const { openBrowser, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const currUser = computed(() => store.state.user);
    const loading = computed(() => store.state.loadingAppPublicData);
    const infos = computed(() => store.state.infos);

    const searchBarRef = ref(null);
    const showSearchBar = ref(false);
    const searchKeyword = ref("");

    // push notification link
    const targetInfoLink = computed(() => route.params.targetInfoLink);

    const onClickSearchIcon = () => {
      showSearchBar.value = true;
      setTimeout(() => {
        searchBarRef.value.$el.setFocus();
      }, 100);
    }

    const filterInfos = () => {
      return infos.value.filter(info => {
        return (searchKeyword.value == "" || info.title.includes(searchKeyword.value));
      });
    }

    // open link from push notifications
    onIonViewDidEnter(() => {
      if (targetInfoLink.value) {
        setTimeout(() => {
          openBrowser(targetInfoLink.value);
        }, 1000);
      }
    });
    watch(targetInfoLink, (currLink) => {
      if (currLink) {
        setTimeout(() => {
          openBrowser(currLink);
        }, 1000);
      }
    });

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, onClickSearchIcon, filterInfos,

      // icons
      search, arrowBack, bookmarksOutline, timeOutline,
      heart, heartOutline, shareSocialOutline,

      // variables
      currUser, userLoggedIn,
      loading, infos,
      searchBarRef, showSearchBar, searchKeyword,
    }
  }
}
</script>

<style scoped>
</style>